
.main_container {
  max-width: 100% !important;
  font-family: Poppins, sans-serif;
}
.main_container .login_main_txt {
  font-size: 45px;
  letter-spacing: 5px;
  text-transform: uppercase;
  color: #122238;
  font-weight: 700;
  -webkit-text-stroke: 2px #122238;
  text-align: center;
  margin-bottom: 15px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3), 0px -4px 10px rgba(255, 255, 255, 0.3) !important;
}
.main_container .main_container.box_let {
  margin-left: 0;
  padding: 50px;
  align-items: center;
  justify-content: center;
}
.main_container .topholder {
  padding-top: 20px !important;
}
.main_container .left_subtitle {
  text-align: center;
  background-color: #122238;
  display: inline-block;
  margin: auto;
  color: #fff;
  padding: 5px 20px;
  font-weight: 600;
  letter-spacing: 1.5px;
  border-radius: 10px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.main_container .left_subtitle::before {
  content: "";
  position: absolute;
  left: -140px;
  height: 3px;
  background-color: #122238;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 140px;
}
.main_container .left_subtitle::after {
  content: "";
  position: absolute;
  right: -140px;
  height: 3px;
  background-color: #122238;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 140px;
}
.main_container .external_txt {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 15px;
}
.main_container .external_txt2 {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 20px;
}
.main_container .main_link_top {
  background-color: #122238;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  margin-left: 10px;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.main_container .submit_btn {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-color: #17858C;
  background-color: #122238;
  font-weight: 700;
  letter-spacing: 2px;
}
.main_container .submit_btn.Mui-focused {
  color: #122238;
  outline: none;
  box-shadow: none;
  transition: inherit;
}
.main_container .submit_btn.Mui-focused .MuiOutlinedInput-notchedOutline,
.main_container .submit_btn.Mui-focused .MuiOutlinedInput-notchedOutline {
  box-shadow: none;
  border-color: #17858C;
  border-width: 2px;
}
.main_container .css-2wdmv4-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: Poppins, sans-serif;;
}
.main_container .submit_btn.Mui-focused {
  color: #17858C;
  font-weight: 600;
}
.main_container .submit_btn.Mui-disabled {
  background-color: #17858C;
  color: #fff;
  font-weight: 700;
  letter-spacing: 2px;
  opacity: .7;
}
.main_container .submit_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main_container .forgot_link {
  color: #122238;
  font-weight: 600;
  text-decoration: none;
}
.main_container.submit_btn {
  background-color: #122238;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;
}
.main_container .css-i6et02-MuiGrid-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.main_container .css-3bn2q6 {
  height: auto;
  padding: 25px 0;
  background-color: #fff ;
}


.main_container .login_box {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 25px 40px;
  border-radius: 20px;
  text-align: left;
}
.main_container .main_title {
  margin-bottom: 10px;
}
.main_container .main_subtitle {
  font-family: Poppins, sans-serif;
  background-color: #5AC534;
  padding: 5px 15px;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
}
.main_container .main_subtitle.forgot::after {
  width: 120px;
  right: -120px;
}
.main_container .main_subtitle.forgot::before {
  width: 120px;
  left: -120px;
}
.main_container .main_subtitle::before {
  content: "";
  position: absolute;
  left: -220px;
  height: 3px;
  background-color: #5AC534;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 220px;
}
.main_container .main_subtitle::after {
  content: "";
  position: absolute;
  right: -220px;
  height: 3px;
  background-color: #5AC534;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 220px;
}
.main_container .main_title {
  font-family: Poppins, sans-serif;
  letter-spacing: .5px;
  font-weight: 700;
  color: #122238;
  font-size: 45px;
}
.main_container .devider {
  padding: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #122238;
  display: inline-block;
  margin: auto;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: .2;
}
.main_container .login_box input.Mui-checked, .main_container .login_box input.MuiCheckbox-indeterminate {
  color: #122238;
}
.main_container .terms_links a {
  color: #17858C;
  text-decoration: none;
  font-weight: bold;
}
.main_container .devider::before {
  content: "";
  position: absolute;
  left: -320px;
  height: 1.5px;
  background-color: #122238;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 320px;
}
.main_container .devider::after {
  content: "";
  position: absolute;
  right: -320px;
  height: 1.5px;
  background-color: #122238;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 320px;
}

.main_container .btm_link_box a {
  color: #122238;
  margin: auto 15px;
  text-decoration: none;
  font-weight: 600;
}
.main_container .social_links > div {
 justify-content: center;
}
.main_container .check_box p {
  font-weight: 600;
}
.main_container .check_box .Mui-checked {
  color: #122238;
}
.main_container .left_coloumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main_container .right_coloumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 15px;
}
.main_container .check_box {
  text-align: left;
}

.main_container .fixed_content {
  width: 40%;
  position: fixed;
  height: 100%;
}


.main_container .single_content h1,
.main_container .single_content h2,
.main_container .single_content p,
.main_container .single_content a,
.main_container .single_content h3,
.main_container .single_content h4,
.main_container .single_content  {
  font-family: Poppins, sans-serif !important;
  margin-bottom: 15px;
}
.main_container .single_content a {
  color: purple;
}
.main_container .single_content {
  padding: 55px !important;
}
.main_container .single_content h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 800;
  position: relative;
  margin-bottom: 40px;
}
.main_container .single_content h1::before {
  position: absolute;
  content: "";
  width: 250px;
  left: 0;
  bottom: -10px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.87);
}
.main_container .single_content h1::after {
  position: absolute;
  content: "";
  width: 25px;
  left: 260px;
  bottom: -10px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.87);
}
.main_container .doctor_list {
  text-align: left;
  margin-top: 25px;
}

.main_container .NewList {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}


/* responsive css */


@media (min-width: 767.98px) and (max-width: 1199px) {
  .main_container .fixed_content {
    width: 38%;
  }
  .main_container .login_main_txt {
    font-size: 32px;
    -webkit-text-stroke: 1px #122238;
    letter-spacing: 2px;
  }
  .main_container .left_subtitle {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .main_container .left_subtitle::before {
    width: 35px;
    left: -35px;
  }
  .main_container .left_subtitle::after {
    width: 35px;
    right: -35px;
  }
  .main_container .main_title {
    font-size: 30px;
  }
  .main_container .main_subtitle {
    font-size: 14px;
  }
  .main_container .devider::before {
    width: 150px;
    left: -150px;
  }
  .main_container .main_subtitle::before {
    width: 50px;
    left: -50px;
  }
  .main_container .devider::after{
    width: 150px;
    right: -150px;
  }
  .main_container .main_subtitle::after {
    width: 50px;
    right: -50px;
  }
  .main_container .main_subtitle.forgot::before {
    width: 50px;
    left: -50px;
  }
  .main_container .main_subtitle.forgot::after {
    width: 50px;
    right: -50px;
  }
  .main_container .check_box p {
    font-size: 13px;
  }
  .main_container .submit_btn.Mui-disabled {
    font-size: 12px;
  }
}
@media only screen and (max-width: 767.98px) {
  .main_container .topholder {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .main_subtitle::before,
  .main_subtitle::after {
    display: none;
  }
  .main_container .devider::before {
    width: 125px;
    left: -125px;
  }
  .main_container .devider::after {
    width: 125px;
    right: -125px;
  }
  
  .main_container .left_coloumn img {
    max-width: 100px;
    margin-right: 10px;
  }
  .main_container .login_main_txt {
    font-size: 25px;
    letter-spacing: 3px;
    text-align: left;
    -webkit-text-stroke:1px;
    margin-bottom: 10px;
  }
  .main_container .left_subtitle {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 5px 10px;
  }
  .main_container .left_subtitle::before,
  .main_container .left_subtitle::after {
    display: none;
  }
  .main_container .main_title {
    font-size: 35px;
  }
  .main_container .login_box {
    padding: 15px 20px;
    height: auto;
  }
  .main_container .social_links {
    margin-bottom: 15px;
  }
  .main_container .submit_btn {
    font-size: 13px;
    margin-top: 0;
  }
  .main_container .terms_links {
    font-size: 13px;
  }
  .check_box {
    margin-bottom: 20px;
  }
  
  .main_container {
    max-width: 100%;
    padding: 0 !important;
  }
  .main_container .left_coloumn {
    flex-direction: row;
    text-align: left;
  }
  .main_container .fixed_content {
    position: relative;
    width: 100%;
  }


  .main_container .single_content {
    padding: 25px !important;
  }
  .main_container .single_content h1 {
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 40px;
  }
  .main_container .single_content h1::before {
    position: absolute;
    content: "";
    width: 150px;
    left: 0;
    bottom: -8px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.87);
  }
  .main_container .single_content h1::after {
    position: absolute;
    content: "";
    width: 15px;
    left: 155px;
    bottom: -8px;
    height: 4px;
  }


}
@media (min-width: 1200px) and (max-width: 1566px) {
  .main_container .login_main_txt {
    font-size: 28px;
  }
  .main_container .left_subtitle {
    font-size: 14px;
  }
  .main_container .left_subtitle::before {
    width: 70px;
    left: -70px;
  }
  .main_container .left_subtitle::after {
    width: 70px;
    right: -70px;
  }
  .main_container .main_title {
    font-size: 35px;
  }
  .main_container .main_subtitle {
    font-size: 14px;
  }
  .main_container .devider::before {
    width: 200px;
    left: -200px;
  }
  .main_container .main_subtitle::before {
    width: 100px;
    left: -100px;
  }
  .main_container .devider::after{
    width: 200px;
    right: -200px;
  }
  .main_container .main_subtitle::after {
    width: 100px;
    right: -100px;
  }
  .main_container .main_subtitle.forgot::before {
    width: 50px;
    left: -50px;
  }
  .main_container .main_subtitle.forgot::after {
    width: 50px;
    right: -50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .main_container .topholder {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .main_subtitle::before,
  .main_subtitle::after {
    display: none;
  }
  .main_container .devider::before {
    width: 125px;
    left: -125px;
  }
  .main_container .devider::after {
    width: 125px;
    right: -125px;
  }
  
  .main_container .left_coloumn img {
    max-width: 100px;
    margin-right: 10px;
  }
  .main_container .login_main_txt {
    font-size: 25px;
    letter-spacing: 3px;
    text-align: left;
    -webkit-text-stroke:1px;
    margin-bottom: 10px;
  }
  .main_container .left_subtitle {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 400;
    padding: 5px 10px;
  }
  .main_container .left_subtitle::before,
  .main_container .left_subtitle::after {
    display: none;
  }
  .main_container .main_title {
    font-size: 35px;
  }
  .main_container .login_box {
    padding: 15px 20px;
    height: auto;
  }
  .main_container .social_links {
    margin-bottom: 15px;
  }
  .main_container .submit_btn {
    font-size: 13px;
    margin-top: 0;
  }
  .main_container .terms_links {
    font-size: 13px;
  }
  .check_box {
    margin-bottom: 20px;
  }
  
  .main_container {
    max-width: 100%;
    padding: 0 !important;
  }
  .main_container .left_coloumn {
    flex-direction: row;
    text-align: left;
  }
}

